
@font-face {
  font-family: "Uni Sans";
  src: url("fonts/uni-sans/Uni\ Sans\ Heavy\ Italic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Uni Sans";
  src: url("fonts/uni-sans/Uni\ Sans\ Heavy.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Uni Sans";
  src: url("fonts/uni-sans/Uni\ Sans\ Thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Uni Sans";
  src: url("fonts/uni-sans/Uni\ Sans\ Thin\ Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Uni Sans', Fallback, sans-serif;
  background-color: rgba(0, 0, 0, 0);
}


body>.wrapper {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

a {
  color: #06b3f0;
}

a:visited {
  color: #06b3f0;
}



@media (min-width: 900px) {
  #root {
    touch-action: manipulation;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
  }
}

@media (max-width: 900px) {
  #root {
    touch-action: none;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
    max-height: 100vh;
  }
}

* {
  touch-action: manipulation;
}

svg {
  width: 100%;
}

.button_gradient {
  background: 'linear-gradient(90deg, rgba(60,60,60,1) 0%, rgba(153,153,153,1) 100%)'
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

select {
  font-size: 60px;
  /* font-weight: bold; */
  text-align: center;
  text-align-last: center;
}

select option {
  background: rgba(0, 0, 0, 0.6);
}

.loge_number {
  font-size: 15px !important;
  text-align: left !important;
  text-align-last: left !important;
}

.job_list {
  font-size: 15px !important;
  text-align: left !important;
  text-align-last: left !important;
}

.loge_number_first {
  color: #ccc !important;
  font-size: 15px !important;
  text-align: left !important;
  text-align-last: left !important;
}

.picker_score {
  font-size: 60px !important;
  font-weight: bold !important;
  text-align: center !important;
  text-align-last: center !important;
}



@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

.text-typing {
  position: relative;
  color: red;
  text-transform: uppercase;
  top: 50%;
  width: 28vw;
  margin: 0 auto;
  border-right: 4px solid rgba(255, 255, 255, .75);
  font-size: 150%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  font-weight: bold;
}

/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(44) 3s infinite normal,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {

  0% {
    width: 0;
  }

  80% {
    width: 25vw;
  }

  100% {
    width: 25vw;
  }


}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, .75);
  }

  to {
    border-right-color: transparent;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 40px;
  left: 20px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  top: 15px !important;
  right: 15px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
/* .bm-menu-wrap {
  position: fixed;
  height: 100%;
} */

/* General sidebar styles */
.bm-menu {
  background: rgba(96, 181, 246, 0.95);
  padding-top: 5px;
  overflow-x: hidden !important
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: transparent
}

/* Wrapper for item list */
.bm-item-list {
  color: #ffffff;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


/* Consent widget */
.cookieConsent__Button {
  color: #06b3f0
}